@import '@styles/colors.module.scss';
@import '@styles/mixins/media';

.spinner {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
