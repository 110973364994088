@import '@styles/colors.module.scss';
@import '@styles/mixins/media';

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
  height: 100%;
  max-width: 375px;

  margin: 24px 16px 24px;

  @include media('tablet') {
    max-width: 490px;
    margin: 158px auto 80px;
  }

  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 32px 24px;
    background-color: $white;
    border-radius: 16px;

    .errorBox {
      background-color: $errorBackground;
      padding: 23px;
      border-radius: 100px;

      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 40px;
    }

    .title {
      width: 100%;
      text-align: center;
      font-size: 28px;
      line-height: 28px;
      margin-bottom: 16px;
      text-align: center;
      font-size: 24px;
    }

    .info {
      color: $label;
      font-size: 16px;
      text-align: center;
      line-height: 20px;
      padding: 0 30px;
    }
  }
}
