@import '@styles/colors.module.scss';
@import '@styles/mixins/media';

.appShowcase {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 64px;
  padding-top: 32px;
  padding-left: 32px;
  padding-right: 32px;
  overflow: hidden;

  background-color: $white;

  border-radius: 16px;

  @include media('tablet') {
    flex-direction: row;
    gap: 30px;
  }

  .content {
    display: flex;
    flex-direction: column;
    max-width: 300px;

    @include media('tablet') {
      max-width: 100%;
      flex: 1;
    }

    .mainText {
      color: $orange;
      font-size: 20px;
      margin-bottom: 18px;
      line-height: 24px;

      .underline {
        color: $orange;
        text-decoration: underline;
      }
    }

    .secondaryText {
      font-size: 14px;
      margin-bottom: 40px;
    }

    @include media('tablet') {
      margin-bottom: 29px;
    }
  }

  .imageContainer {
    min-height: 257px;
    position: relative;

    @include media('tablet') {
      flex: 1;
      min-height: 255px;
    }

    .image {
      width: 600px;
      height: 400px;
      position: absolute;
      left: 56px;

      @include media('tablet') {
        width: 434px;
        height: 248px;
        position: absolute;
        bottom: -23% !important;
        right: -135% !important;
        left: 31px;
      }
    }
  }

  .mbSmall {
    margin-bottom: 18px;
  }

  .mgLarge {
    margin-bottom: 40px;
  }
}
